import { useContext } from "react";
import { ProductListingInfo } from "../../context";
import { NavLink } from "react-router-dom";
import { ShimmerPostItem } from "react-shimmer-effects";

const AllTags = () => {
  const { footerData, isFilterDataLoading } = useContext(ProductListingInfo);

  

  return (
    <>
      {
        footerData &&
        <div className="all_tags_main">
          {isFilterDataLoading == true ? (
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <ShimmerPostItem card title text cta></ShimmerPostItem>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <ShimmerPostItem card title text cta></ShimmerPostItem>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <ShimmerPostItem card title text cta></ShimmerPostItem>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
                <ShimmerPostItem card title text cta></ShimmerPostItem>
              </div>
            </div>
            // <div className="rotating-circle-main">
            //   <LoadingBar />
            // </div>
          ) : (
            <div className="container">
              {Object.entries(footerData).map(function (value, index) {
                return (
                  <div className="tag_info" key={index}>
                    <div className="tags_heading">
                      <h2>{value[0]}</h2>
                    </div>

                    <div className="tags_box">
                      {
                        value[1].length > 0 &&
                        value[1].map((item, index) => {
                          return (
                            <NavLink to={`/${item.slug}`} target="_blank" key={index}>
                              <div className="tags_inner_wid" >
                                <div className="tags_count">
                                  <span>{index + 1}</span>
                                </div>
                                <div className="tags_text">
                                  <h2>{item.title}</h2>
                                </div>
                              </div>
                            </NavLink>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}


        </div>
      }
    </>
  );
};

export default AllTags;
