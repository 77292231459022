import FacebookLogin from 'react-facebook-login';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { socialLogin } from '../../actions/loginAction';
import { useTranslation } from 'react-i18next';
const FacebookLoginComponent = (props) => {
    const { t } = useTranslation();
    const { LogincloseModal } = props;
    // const facebookAppId = "3275814556046425";
    // const facebookAppId = "1702898383609719";
    const facebookAppId = "649361007536957"; 
    
    const navigate = useNavigate();
    const dispatch = useDispatch();



    const responseFacebook = (response) => {
      console.log("Facebook Response:", response);

      if (response.status === "not_authorized") {
        console.log("User denied permissions. Prompting login again...");
        
        document.querySelector(".kep-login-facebook").click(); 
      }
      
      if (response.status === "unknown") {
        console.log("User not authenticated. Triggering login...");
        
        document.querySelector(".kep-login-facebook").click();
      } else if (response.accessToken) {
        const userData = {
          email: response.email,
          user_name: response.name,
          image: response.picture?.data?.url,
          user_type: "Customer",
          login_type: "facebook",
        };
    
        dispatch(socialLogin(userData, LogincloseModal, navigate));
      } else {
        console.log("Facebook login failed!");
      }
    };
    
    

  
    return (
        <>
            {
                facebookAppId !== "" &&
                <FacebookLogin
                    appId={facebookAppId}
                    autoLoad={false}
                    fields="email,name,picture"
                    callback={responseFacebook}
                    scope="public_profile,email"
                    textButton={
                        // <img
                        //     src={images["facebook-login-button.png"]}
                        //     alt=""
                        // />
                        t("_login_with_facebook")
                    }
                    icon="fa-facebook"
                     authType="reauthenticate"
                // cssClass=""
                />
            }
        </>
    )
}

export default FacebookLoginComponent;
