import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import "flatpickr/dist/flatpickr.css";
import { useTranslation } from "react-i18next";
import { PrincingScheduleContext, ProductBuilderContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import PricingScheduleStepOne from "./PricingSchedule/PricingScheduleStepOne";
import PricingScheduleStepTwo from "./PricingSchedule/PricingScheduleStepTwo";
import PricingScheduleStepThree from "./PricingSchedule/PricingScheduleStepThree";
import {
  getReservationProduct,
  uplodProductNew,
} from "../../actions/productBuilderAction";
import OptionList from "./PricingSchedule/OptionList";
import { startSpinLoader, validateField } from "../../actions/customFn";
import PricingScheduleStepTwoNew from "./PricingSchedule/PricingScheduleStepTwoNew";

const PricingSchedule = () => {
  const { t } = useTranslation();
  const { tour_id, option_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const {
    setTourId,
    getData,
    setDataLoad,
    dataLoad,
    runningDtep,
    productType,
    setSpinLoader,
    saveAndExit,
    productInfo,
    exitRoute,
    languageIdSelected,
  } = useContext(ProductBuilderContext);
  const [pricingScheduleData, setData] = useState("");
  const [slidepage, setSlidePages] = useState(1);
  const [optiontmodel, setOptionmodel] = useState(false);
  // -- model
  const CloseAddOPtionModel = () => setOptionmodel(false);
  const ShowAddOPtionModel = () => setOptionmodel(true);
  const [editData, setEditData] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [optionData, setOptionList] = useState("");
  const [reservationMsg, setsReservationMsg] = useState("");
  const [allReservationsDates, setAllReservationsDates] = useState([]);
  const [formDataNew, setFormDataNew] = useState({
    option_type: "",
    option_list: [],
    step: "create_option",
    tourId: tour_id,
  });
  const [optionImage, setOptionImage] = useState({ optionImage: "" });

  const [connect_tourysta, setConnect_tourysta] = useState(false);
 

  console.log("fsaagagaga",pricingScheduleData)


   
  const [formData, setFormData] = useState({
    tourId: tour_id,
    option_id: "",
    pricing_id: "",
    product_type: productType,
    step: "twelve",
    language: productInfo.productLanguageId,
    option_title: "",
    reference_code: "",
    is_private: "Non-private",
    existing_line: "no",
    likely_to_sell_out: "no",
    existing_line_type: "no",
    is_cancelled: false,
    cancelled_time: "",
    cancelled_type: "day",
    maximum_person_booking: "",
    pricing_type: "person",
    is_discount: "no",
    validity_from: "",
    validity_to: "",
    unique_availiblity_date: [],
    weekly_availiblity: {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    },
    is_time_slot_select: "no",
    price_name: "",
    minimum_participant: "",
    optionId: option_id,
    age_group: [],
    prices: {
      adult: {
        age_from: 0,
        age_to: 99,
        booking_category: "standard",
        no_of_people: [
          {
            from: 1,
            to: 51,
          },
        ],
        retail_price: [0],
        commission: [30],
        payout_per_person: [0],
      },
    },
    weekly_discount: {
      mon: "",
      tue: "",
      wed: "",
      thu: "",
      fri: "",
      sat: "",
      sun: "",
    },
    unique_discount_date: [],
    discount_validity_from: "",
    discount_validity_to: "",
    discountId: 0,
    discount_percentage: "",
    option_pricing_id: "",

    option_address: "",
    option_latitude: "",
    option_longitude: "",
    option_location_obj: "",
    use_availibilty: "no",
    external_product_id: "",
    booking_system: "",
    configure_automatically: "availabilty_only",
    is_fixed_time: "no",
    fixed_availiblity: {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    },
    fixed_unique_availiblity_date: [],
    optionImage: "",
  });

  const [formErrors, setFormError] = useState({});
  const errors = {};

  useEffect(() => {
    let sendData = {
      option_id: formData.option_id,
      pricing_id: formData.pricing_id,
    };
    if (formData.option_id) {
      getData("twelve", setData, sendData);
    }
  }, [formData.option_id, languageIdSelected, languageReducer]);

  useEffect(() => {
    if (languageReducer) {
      setFormData((prev) => ({
        ...prev,
        ...pricingScheduleData.product_data,
        product_type: productType,
        language: productInfo.productLanguageId,

      }));


      if(pricingScheduleData){
        setConnect_tourysta(pricingScheduleData?.connect_tourysta)
      }

      if (pricingScheduleData?.product_data?.use_availibilty == "yes") {
        setAllReservationsDates(
          pricingScheduleData?.product_data?.unique_availiblity_date
        );
      }
    }
  }, [pricingScheduleData]);

  const submitData = () => {
    // const newUpdateFormData = formData;

    let validate = {
      price_name: "Required|",
      minimum_participant: "Required|",
    };
    validateField(validate, { ...formData }).then((res) => {
      if (res == true) {
        if (formData?.use_availibilty == "yes") {
          delete formData?.weekly_availiblity;
          delete formData?.unique_availiblity_date;

          delete formData?.fixed_availiblity;
          delete formData?.fixed_unique_availiblity_date;
        } else {
          if (formData?.is_fixed_time == "yes") {
            delete formData?.weekly_availiblity;
            delete formData?.unique_availiblity_date;
          } else {
            delete formData?.fixed_availiblity;
            delete formData?.fixed_unique_availiblity_date;
          }
        }

        if (languageReducer !== "") {
          startSpinLoader("cover-spin");
          let nextRoute = "price-schedule";
          dispatch(
            uplodProductNew(
              languageReducer,
              "",
              navigate,
              formData,
              nextRoute,
              "",
              "",
              "",
              "",
              CloseAddOPtionModel,
              setIsUpload,
              setSpinLoader,
              setSlidePages,
              "",
              "",
              resetFormdata,
              setsReservationMsg,
              optionImage
            )
          );
        }
      }
    });
  };

  useEffect(() => {
    if (isFirstRender == false) {
      navigate(`/my-tours`);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  // =============scroll on step change starts=======
  const targetDivRef = useRef(null);
  const scrollToDiv = () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  // =============scroll on step change ends=======

  const addNewOption = () => {
    resetFormdata();
    ShowAddOPtionModel();
  };

  const resetFormdata = () => {
    setFormData((formData) => ({
      tourId: tour_id,
      option_id: "",
      pricing_id: "",
      product_type: productType,
      language: productInfo.productLanguageId,
      step: "twelve",
      option_title: "",
      reference_code: "",
      is_private: "Non-private",
      existing_line: "no",
      likely_to_sell_out: "no",
      existing_line_type: "no",
      is_cancelled: false,
      cancelled_time: "",
      cancelled_type: "day",
      maximum_person_booking: "",
      pricing_type: "person",
      is_discount: "no",
      validity_from: "",
      validity_to: "",
      unique_availiblity_date: [],
      weekly_availiblity: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      },
      is_time_slot_select: "no",
      price_name: "",
      minimum_participant: "",
      tourId: tour_id,
      optionId: option_id,
      age_group: [],
      prices: {
        adult: {
          age_from: 0,
          age_to: 99,
          booking_category: "standard",
          no_of_people: [
            {
              from: 1,
              to: 51,
            },
          ],
          retail_price: [0],
          commission: [30],
          payout_per_person: [0],
        },
      },
      weekly_discount: {
        mon: "",
        tue: "",
        wed: "",
        thu: "",
        fri: "",
        sat: "",
        sun: "",
      },
      unique_discount_date: [],
      discount_validity_from: "",
      discount_validity_to: "",
      discountId: 0,
      discount_percentage: "",
      option_pricing_id: "",
      option_address: "",
      option_latitude: "",
      option_longitude: "",
      option_location_obj: "",
      use_availibilty: "no",
      external_product_id: "",
      booking_system: "",
      configure_automatically: "availabilty_only",
      fixed_availiblity: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      },
      fixed_unique_availiblity_date: [],
    }));
  };

  // const [formErrors, setFormError] = useState({});
  // const errors = {};

  const goToStepTwo = () => {
    let validate = {
      option_title: "Required|",
      reference_code: "Required|",
      option_description: "Required|",
      maximum_person_booking: "Required|",
      option_address: "Required|",
      cancelled_time: formData.is_cancelled == true ? "Required|min:1" : "",
    };

    validateField(validate, { ...formData }).then((res) => {
      if (res == true) {
        setSlidePages(2);
        scrollToDiv();
      }
    });
  };

  const goToStepThree = async () => {
    let validate = {};
    let isProductValid = false;

    if (formData.use_availibilty == "yes") {
      validate = {
        booking_system: "Required|",
        external_product_id: "Required|",
        configure_automatically: "RequiredNoBorder|",
      };

      if (
        formData.booking_system != null &&
        formData.booking_system != "" &&
        formData.external_product_id != null &&
        formData.external_product_id != "" &&
        formData.configure_automatically != null &&
        formData.configure_automatically != ""
      ) {
        startSpinLoader("cover-spin");
        const reservation_data = {
          reservation_id: formData.booking_system,
          external_product_id: formData.external_product_id,
          configure_automatic: formData.configure_automatically,
          // booking_system:formData.booking_system
        };

        isProductValid = await getReservationProduct(
          languageReducer,
          navigate,
          reservation_data,
          setsReservationMsg,
          setSpinLoader
        );
      }
    } else {
      validate = {
        validity_from: "Required|",
      };
    }

    // validate = {
    //   validity_from: "Required|",
    // };
    // ==================To check atleat one of weekday has time slot starts======

    if (isProductValid == false && formData.use_availibilty == "no") {
      let arrayObject =
        formData.is_fixed_time == "yes"
          ? formData.fixed_availiblity
          : formData.weekly_availiblity;

      // let arrayObject = formData.weekly_availiblity;
      let isAvailable = false;

      Object.values(arrayObject).forEach((item) => {
        if (item.length > 0) {
          isAvailable = true;
          isProductValid = true;
        }
      });
      if (isAvailable == false) {
        errors.weekelyTimeError =
          "Select atleat one weekely starting time to proceed";
      }
    }

    setFormError(errors);
    // ==================To check atleat one of weekday has time slot ends======
    validateField(validate, { ...formData }).then((res) => {
      if (
        res == true &&
        Object.keys(errors).length == 0 &&
        isProductValid == true
      ) {
        setsReservationMsg("");
        setSlidePages(3);
        scrollToDiv();
      }
    });
  };

  const closeModel = () => {
    CloseAddOPtionModel();
    setFormData((prev) => ({ ...prev, option_id: "" }));
    setsReservationMsg("");
  };

  useEffect(() => {
    getData("option_list", setOptionList);
  }, [isUpload, languageReducer, languageIdSelected]);

  return (
    <PrincingScheduleContext.Provider
      value={{
        formData,
        setFormData,
        // handleChange,
        formErrors,
        slidepage,
        setSlidePages,
        CloseAddOPtionModel,
        submitData,
        ShowAddOPtionModel,
        setEditData,
        isUpload,
        setIsUpload,
        // pricingType, setPricingType,
        scrollToDiv,
        optionData,
        setOptionList,
        formDataNew,
        setFormDataNew,
        reservationMsg,
        optionImage,
        setOptionImage,
        allReservationsDates,
        connect_tourysta,
        productInfo
        
      }}
    >
      {dataLoad == true && (
        <div className="meet-pickup-main pricing_schedule_section">
          <div className="pricing_schedulepage activity_productdetail">
            <h1>
              {/* Create pricing schedules */}
              {t("_Create_pricing_schedules")}
            </h1>

            <OptionList />

            <div className="add_anotheroption">
              <div className="fix_width">
                <h3>
                  {t("_Add")}
                  {formDataNew.option_list.length > 0 && " another "}{" "}
                  {t("_option")}
                </h3>
                <p>
                  {/* An option is a unique version of your product that offers
                specific languages, starting points, or customizations. */}
                  {t(
                    "_An_option_is_a_unique_version_of_your_product_that_offers_specific_languages_starting_points_or_customizations."
                  )}
                </p>
              </div>
              <button
                className="onlyborder_btn"
                // onClick={ShowAddOPtionModel}
                onClick={() => addNewOption()}
              >
                {t("_Add_option")}
              </button>
            </div>

            <div className="btn-last-save">
              <button
                className="btn-all-save"
                onClick={
                  () => navigate(`/product-bulider/booking-process/${tour_id}`)
                  // navigate(`/product-bulider/cancelling-policy/${tour_id}`)
                }
                // onClick={() => goToMyTours()}
              >
                {t("_Save_&_continue")}
              </button>
            </div>

            {/* <div className='add_anotheroption'>

            <div className='fix_width'>
              <h3>Currency: AUD</h3>
              <p>Your prices for this product are set in AUD </p>
            </div>
            <button className='onlyborder_btn'>Edit</button>
          </div> */}
            <div>
              <Modal
                show={optiontmodel}
                //   onHide={CloseTicketModel}
                className="inclusion_pagemodels pricingschedule_models"
              >
                <Modal.Body ref={targetDivRef}>
                  <div className="close_modelbutn">
                    {/* <button onClick={CloseAddOPtionModel}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z"
                        fill="#4f4d4d"
                      />
                    </svg>
                  </button> */}
                  </div>

                  {slidepage == 1 && <PricingScheduleStepOne />}

                  {slidepage == 2 && <PricingScheduleStepTwo />}
                  {/* {slidepage == 2 && <PricingScheduleStepTwoNew />} */}

                  {slidepage == 3 && <PricingScheduleStepThree />}

                  <div className="pricebelow_allbutnsection">
                    {slidepage == 1 && (
                      <>
                        <button
                          className="remove-allcancel mr-4"
                          onClick={closeModel}
                        >
                          {/* Cancel */}
                          {t("_cancel")}
                        </button>

                        <div className="slide_circles">
                          <div className="circle_one darkcircle"></div>
                          <div className="circle_one"></div>
                          <div className="circle_one"></div>
                        </div>

                        <button
                          className="btnall-savenext "
                          onClick={() => {
                            goToStepTwo();
                            // setSlidePages(2);
                            // scrollToDiv();
                          }}
                        >
                          {t("_Next")}
                        </button>
                      </>
                    )}

                    {slidepage == 2 && (
                      <>
                        <button
                          className="remove-allcancel mr-4"
                          onClick={() => {
                            setSlidePages(1);
                            scrollToDiv();
                          }}
                        >
                          {t("_Back")}
                        </button>

                        <div className="slide_circles">
                          <div className="circle_one"></div>
                          <div className="circle_one darkcircle"></div>
                          <div className="circle_one"></div>
                        </div>

                        <button
                          className="btnall-savenext "
                          onClick={() => {
                            goToStepThree();
                            // setSlidePages(3);
                            // scrollToDiv();
                          }}
                        >
                          {t("_Next")}
                        </button>
                      </>
                    )}

                    {slidepage == 3 && (
                      <>
                        <button
                          className="remove-allcancel mr-4"
                          onClick={() => {
                            setSlidePages(2);
                            scrollToDiv();
                          }}
                        >
                          {t("_Back")}
                        </button>

                        <div className="slide_circles">
                          <div className="circle_one"></div>
                          <div className="circle_one"></div>
                          <div className="circle_one darkcircle"></div>
                        </div>
                        <button
                          onClick={submitData}
                          className="btnall-savenext "
                        >
                          {t("_Submit")}
                        </button>
                      </>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </PrincingScheduleContext.Provider>
  );
};

export default PricingSchedule;
